<template>
  <div class="main-view-padding has-header">
    <div class="header main" v-header-position-lock>
      <div class="align-left"></div>
      <div class="align-middle">
        <headerbuttonstack :state="headerButtons" />
      </div>
      <div class="align-right"></div>
    </div>
    <div
      style="
        font-size: 32px;
        text-align: left;
        color: #e51550;
        border-bottom: 1px solid #ccc;
        padding: 5px;
        font-weight: 400;
        margin-bottom: 25px;
      "
    >
      Individuelle Lohnarten Konfigurator
    </div>

    <div style="max-width:700px; margin: 0 auto;">
      <table class="table">
        <tr>
          <th>Nummer</th>
          <th>Name</th>
          <th>Farbe</th>
          <th>Lohnart Details</th>
          <th>Bearbeiten</th>
        </tr>
        <tr v-for="x in individualWageTypes" v-bind:key="x.id" style="margin:0px;">
          <td style="padding:1px; font-size:14px;">{{ x.numberString }}</td>
          <td style="padding:1px; font-size:14px;">{{ x.name }}</td>
          <td style="display: flex; align-items: center; align-content: center; padding:4px 0px 0px 0px;">
            <div
              style="
                width: 15px;
                height: 15px;
                background-color: #f00;
                text-align: center;
                cursor: pointer;
                margin: 0 auto;
              "
              v-bind:style="{ 'background-color': x.color }"
              v-on:click="toggleColorPicker(x)"
            ></div>
          </td>
          <td style="padding:1px; font-size:14px;">
            <i
              class="fas fa-circle-exclamation"
              v-if="!x.details"
              v-tooltip="
                'Für diese Lohnart wurden noch keine Berechnungsdetails angelegt.'
              "
              style="color: var(--ml)"
              >&nbsp;</i
            >
            <i class="fas fa-edit" v-on:click="openEditEntityDetails(x)"></i>
          </td>
          <td style="padding:1px; font-size:14px;">
            <i class="fas fa-edit" v-on:click="openEditEntity(x)"></i>
          </td>
        </tr>
      </table>
    </div>
    <br />
    <hr />
    <h4>Export-Mapping Tabelle</h4>
    <MappingEmploymentModelWageTypes ref="child"></MappingEmploymentModelWageTypes>

    <b-modal size="md" ref="modal-loading" @close="" :no-close-on-backdrop="true" :no-close-on-esc="true" centered>
      <template slot="modal-header">
        <div></div>
      </template>
      <b-container fluid>
        <div class="full">
          <div class="label left" style="text-align: center">
            <i class="fas fa-spinner fa-spin" style="font-size: xxx-large"></i> <br/><br/>
            Bitte warten<br />
            Daten werden geladen...<br />
          </div>
        </div>
      </b-container>
      <template slot="modal-footer">
        <div></div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import button from "../uicomponents/button.vue";
import MappingEmploymentModelWageTypes from "./MappingEmploymentModelWageTypes.vue";

export default {
  components: { button, MappingEmploymentModelWageTypes },
  name: "IndividualWageTypes",
  data() {
    return {
      loading: true,
      saves: false,
      individualWageTypes: [],
      mdWagetypes: [],
      stores: [],
      storeCountries: [],
      mdCollectiveContracts: [],
      spots: [],
      mdBonusPaymentSettings: [],

      employeeCategoryMap: {},
      employeeLevelMap: {},
      contractTypeMap: {},
      employmentTypeMap: {},
      paymentTypeMap: {},
      wageTypeMap: {},
      nightWorkerTypeMap: {},
      nightWorkModeMap: {},
      exitReasonMap: {},
    };
  },
  created: function () {
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  mounted: function () {
    console.log("MOUNTED");
    this.setupUIDataMaps();
    if (this.$store.state.jwt != undefined && this.$store.state.companyId != undefined) {
      this.refresh();
    }
  },
  computed: {
    ...mapGetters(["companyId", "countryEnumMap", "uiDataMap", "isSuperUser", "companyArray"]),
    headerButtons() {
      let view = this;

      let state = {
        buttons: [],
      };
      state.buttons.push({
        type: "block",
        icon: "fa-refresh",
        click: () => {
          view.refresh();
        },
        tooltip: "Daten neu laden",
      });
      state.buttons.push({
        type: "block",
        icon: "fa-plus",
        click: ($event) => {
          view.openNewWageType($event);
        },
        tooltip: "Neu anlegen",
      });
      state.buttons.push({
        type: "block",
        icon: "fa-file-import",
        click: ($event) => {
          view.openImportMdBonusPaymentSettings($event);
        },
        tooltip: "Bonus Einstellung aus MD importieren",
      });
      state.buttons.push({
        type: "block orange",
        icon: "fa-file-import",
        show: view.companyArray.length > 1,
        click: ($event) => {
          view.openImportBonusPaymentSettingsFromCompanySelect($event);
        },
        tooltip: "Bonus Einstellungen aus anderer Company importieren",
      });
      return state;
    },
  },
  watch: {
    companyId: function (val, oldVal) {
      this.refresh();
    },
  },
  // methods that implement data logic.
  methods: {
    refresh: function (force = false) {
      let view = this;
      view.loading = true;

      if (view.$refs["child"]) {
        view.$refs["child"].refresh();
      }

      let promises = [];

      promises.push(view.$helpers.GetStoresSettings(true).then((response) => {
        view.stores = response.data;
        // console.log("stores", view.stores);
        view.stores.forEach(s => {
          if(!view.storeCountries.includes(s.country)){
            view.storeCountries.push(s.country);
          }
        });
      }));

      promises.push(view.loadWageTypes());
      promises.push(view.loadIndividualWageTypes());
      promises.push(view.loadMdCollectiveContracts());
      promises.push(view.loadSpots());
      promises.push(view.$helpers.GetCountryEnum());

      Promise.all(promises)
        .then(() => {
          console.log("loading done");
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          view.loading = false;
        });
    },
    loadIndividualWageTypes: function () {
      let view = this;
      let params = {};

      return view.$helpers
        .GetCall("/api/sec/individualWageTypes", params)
        .then((response) => {
          let data = response.data.data;

          // Sorting the data by numberString before further processing
          data.sort((a, b) => {
            // Handle cases where numberString might be undefined or null
            const numA = a.numberString ? parseFloat(a.numberString) : Infinity;
            const numB = b.numberString ? parseFloat(b.numberString) : Infinity;
            return numA - numB;
          });

          let tmp = [];
          // Looping through sorted data and creating objects
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              numberString: data[i].numberString,
              name: data[i].name,
              sorting: data[i].sorting,
              color: data[i].color,
              details: data[i].details,
            };
            tmp.push(item);
          }
          view.individualWageTypes = tmp;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    loadWageTypes: function () {
      let view = this;
      let url = "/api/sec/wagetypes";
      let params = {};
      return view.$helpers
        .GetCall(url, params)
        .then((response) => {
          let data = response.data;
          let tmp = [];
          for (let i = 0; i < data.length; i++) {
            let item = {
              id: data[i].id,
              key: data[i].key,
              name: data[i].name
            };
            tmp.push(item);
          }
          view.mdWagetypes = tmp.slice();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadMdCollectiveContracts: function () {
      let view = this;
      let url = "/api/sec/proxy/md/collectiveContracts";
      let params = {};
      return view.$helpers
        .GetCall(url, params)
        .then((response) => {
          let data = response.data;
          let tmp = [];
          for (let i = 0; i < data.length; i++) {
            tmp.push(data[i]);
          }
          tmp.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          view.mdCollectiveContracts = tmp.slice();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadSpots: function () {
      let view = this;
      let url = "/api/sec/proxy/md/spots";
      let params = {};
      return view.$helpers
        .GetCall(url, params)
        .then((response) => {
          // console.log(response.data.data);
          let data = response.data.data;
          let tmp = [];
          Object.values(data).forEach((spot) => {
            tmp.push(spot);
          });
          tmp.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
          view.spots = tmp.slice();
          // console.log("spots", view.spots);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadMdBonusPaymentSettings: function () {
      let view = this;
      let url = "/api/sec/proxy/md/bonuspaymentsettings";
      let params = {};
      return view.$helpers
        .GetCall(url, params)
        .then((response) => {
          let data = response.data.data;
          let tmp = [];
          console.log(data);
          Object.values(data).forEach((storeBonusPaymentSetting) => {
            storeBonusPaymentSetting.forEach((bonusPaymentSetting) => {
              tmp.push(bonusPaymentSetting);
            });
          });
          tmp.sort((a, b) => {
            if (a.storeId === b.storeId) {
              return a.name.localeCompare(b.name);
            } else {
              return a.storeId - b.storeId;
            }
          });
          view.mdBonusPaymentSettings = tmp.slice();
          return view.mdBonusPaymentSettings;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadBonusPaymentSettingsFromCompany: function (companyId) {
      let view = this;
      let url = "/api/sec/individualWageTypes/getForCompany";
      let params = {
        foreignCompanyId: companyId,
      };
      return view.$helpers
        .GetCall(url, params)
        .then((response) => {
          let data = response.data.data;
          let tmp = [];
          // console.log(data);
          return data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    openNewWageType(event) {
      let view = this;
      let dataEntries = [
        {
          label: "Nummer: ",
          key: "numberString",
          hidden: false,
          type: "numeric",
          value: 0,
          min: 0,
          max: 65535,
          styles: { width: "350px" },
        },
        {
          label: "Name: ",
          key: "name",
          hidden: false,
          type: "text",
          value: "",
          length: 128,
          styles: { width: "350px" },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: 1, slider: {value: 1, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: event.currentTarget,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (newWageType) {
            view.createIndividualWageType(newWageType);
          },
          errorCheck: {
            // numberString: (entries) => {
            //   if (
            //     entries.hasOwnProperty("numberString") &&
            //     view.individualWageTypes.find((f) => f.numberString === entries.numberString)
            //   )
            //     return {
            //       key: "numberString",
            //       type: "error",
            //       text: "Wird bereits verwendet",
            //     };
            //   else return null;
            // },
            name: (entries) => {
              if (
                entries.hasOwnProperty("name") &&
                view.individualWageTypes.find((f) => f.name === entries.name)
              )
                return {
                  key: "name",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
          },
        },
        header: "Neue Lohnart anlegen",
        remainAfterSelect: false,
        expanded: true,
      });
    },
    openEditEntity(entity) {
      let view = this;

      let dataEntries = [
        {
          label: "id: ",
          key: "id",
          hidden: true,
          type: "text",
          value: entity.id,
          length: 128,
          styles: { width: "350px" },
        },
        {
          label: "Nummer: ",
          key: "numberString",
          hidden: false,
          type: "numeric",
          value: entity.numberString,
          min: 0,
          max: 65535,
          styles: { width: "350px" },
        },
        {
          label: "Name: ",
          key: "name",
          hidden: false,
          type: "text",
          value: entity.name,
          length: 128,
          styles: { width: "350px" },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: wt.sorting, slider: {value: wt.sorting, min: 1, max: 100}},
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (eEntity) {
            view.editEntity(eEntity);
          },
          errorCheck: {
            // numberString: (entries) => {
            //   if (
            //     entries.hasOwnProperty("numberString") &&
            //     view.individualWageTypes.find(
            //       (f) =>
            //         f.numberString !== entity.numberString &&
            //         f.numberString === entries.numberString
            //     )
            //   )
            //     return {
            //       key: "numberString",
            //       type: "error",
            //       text: "Wird bereits verwendet",
            //     };
            //   else return null;
            // },
            name: (entries) => {
              if (
                entries.hasOwnProperty("name") &&
                view.individualWageTypes.find((f) => f.name !== entity.name && f.name === entries.name)
              )
                return {
                  key: "name",
                  type: "error",
                  text: "Wird bereits verwendet",
                };
              else return null;
            },
          },
        },
        header: "Lohnart bearbeiten",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openEditEntityDetails(entity) {
      console.log(entity);
      if(entity.id == 18){
        let dataEntries = [
          {
            label: "",
            key: "numberString",
            hidden: false,
            type: "output",
            value: "Für diese Lohnart wird immer der Wert 7,8 Stunden übertragen.",
          }
        ];
        this.$helpers.OpenPopup({
          elRelative: null,
          type: "datafill",
          pointerDirection: 0,
          componentState: {
            positiveButtonLabel: "Popup schließen",
            entries: dataEntries,
            selectCallback: function (eWageType) {
            },
          },
          header: "Information",
          remainAfterSelect: false,
          centered: true,
          expanded: true,
        });
        return;
      }
      if(entity.id == 155 || entity.id == 156){
        let dataEntries = [
          {
            label: "",
            key: "numberString",
            hidden: false,
            type: "output",
            value: "Für diese Lohnart wird immer der Wert 1 Stunde übertragen.",
          }
        ];
        this.$helpers.OpenPopup({
          elRelative: null,
          type: "datafill",
          pointerDirection: 0,
          componentState: {
            positiveButtonLabel: "Popup schließen",
            entries: dataEntries,
            selectCallback: function (eWageType) {
            },
          },
          header: "Information",
          remainAfterSelect: false,
          centered: true,
          expanded: true,
        });
        return;
      }

      let view = this;

      let bonusTypes = [
        {value: 0, label: 'Ohne Bonus'},
        {value: 1, label: 'Mit Bonus'},
        {value: 2, label: 'Nur Bonus'},
      ]
      // let bonusModeItems = [
      //   // { value: 1, label: 'Stunden Bonus bis zur Grenze, Pauschale ab Grenze'},
      //   { value: 2, label: "Pauschale"}, // sobald Zeitraum beginnt" },
      //   { value: 3, label: "Stunden Bonus"}, // sobald Zeitraum beginnt" },
      //   // { value: 4, label: 'Pauschale ab Grenze'},
      //   // { value: 5, label: 'Stunden Bonus ab Grenze'},
      // ];
      let bonusModeItems = Object.entries(view.nightWorkModeMap).map(([id, label]) => {
          return {
              label: label,
              value: parseInt(id),
              alwaysShow: [2,3].includes(parseInt(id)),
              selected: entity.details && entity.details.bonusMode ? entity.details.bonusMode == parseInt(id) : false
          }
      });
      console.log("bonusModeItems", bonusModeItems);
      console.log("entity.details.workType", entity.details ? entity.details.workType : "no details");
      console.log(bonusModeItems.filter(bm => {
        if (entity.details && entity.details.workType != 0) {
          return bm.alwaysShow;
        } else {
          return true;
        }
      }));

      let workTypes = [
        { value: 0, label: "Arbeitszeit" },
        { value: 1, label: "Abwesenheit" },
        { value: 2, label: "Andere Kosten" },
        { value: 3, label: "Bonuszahlung" },
        { value: 4, label: "Monatssummen" },
        { value: 5, label: "Monatsabschluss" },
      ];
      let mdEmployeeAvailabilityTypes = [
        { value: 1, label: "Urlaub" },
        { value: 2, label: "Krankheit" },
        { value: 3, label: "Feiertags Ausgleich" },
        { value: 4, label: "Sonstiges" },
        // { value: 5, label: "Kein Vertrag" },
        { value: 6, label: "Pflegeurlaub" },
        { value: 7, label: "Ausbildung" },
        { value: 8, label: "Lohnfortzahlung" },
      ];
      let mdEmployeeAvailabilitySubTypes = [
        // { value: -1, parentType: 2, label: "Kein Detail" }, // -> deprecated, changed to 22 and 23
        { value: 1, parentType: 8, label: "Sonstiges" },
        { value: 2, parentType: 8, label: "Hochzeit" },
        { value: 3, parentType: 8, label: "Todesfall" },
        { value: 4, parentType: 8, label: "Beerdigung" },
        { value: 5, parentType: 8, label: "Geburt eines Kindes" },
        { value: 6, parentType: 8, label: "Umzug" },
        { value: 7, parentType: 8, label: "Präsenzdienst" },
        { value: 8, parentType: 8, label: "Arbeitsunfall" },
        { value: 9, parentType: 8, label: "Arztbesuch" },
        { value: 10, parentType: 8, label: "Vorladung zu Behörde" },
        { value: 11, parentType: 8, label: "Quarantäne" },
        { value: 12, parentType: 4, label: "Zeitausgleich" },
        { value: 13, parentType: 2, label: "Krankenstand ohne Arztbestätigung" },
        { value: 14, parentType: 8, label: "Geburtstag" },
        { value: 15, parentType: 4, label: "Unbegründete Abwesenheit" },
        { value: 16, parentType: 4, label: "Unbezahlter Urlaub" },
        { value: 17, parentType: 4, label: "Freistellung" },
        { value: 18, parentType: 8, label: "Bürotag" },
        { value: 19, parentType: 8, label: "Home Office" },
        { value: 20, parentType: 8, label: "Feiertagsentgelt" },
        { value: 21, parentType: 2, label: "Staatliches Krankengeld" },
        { value: 22, parentType: 2, label: "Regulärer Krankenstand" },
        { value: 23, parentType: 4, label: "Kein Detail" },
      ];
      let mdStoresEnabled = [];
      for (let i = 0; i < view.stores.length; i++) {
        mdStoresEnabled.push({ value: view.stores[i].id, label: view.stores[i].name });
      }
      let extraCostTypes = [
        { value: 1, label: "Personalessen" },
        { value: 2, label: "Garderobe" },
        { value: 10, label: "Vorschuss" },
        { value: 11, label: "Miete" },
        { value: 12, label: "Bussgeld" },
        { value: 14, label: "Arbeitsschuhe" },
        { value: 15, label: "Schlüssel" },
        { value: 16, label: "Lernmaterial" },
        { value: 17, label: "Bearbeitungsgebühr" },
        { value: 20, label: "Abzug Kleidung" },
        { value: 22, label: "Kaution Kleidung" },
        { value: 23, label: "Vertragsstrafe" },
        { value: 0, label: "Sonstiges" },
      ];
      let bonusExpenseTypes = [
        { value: 2, label: "Garderobe" },
        { value: 3, label: "Nachtarbeit" },
        { value: 4, label: "Wochenendarbeit" },
        { value: 5, label: "Urlaubsentgelt" },
        { value: 6, label: "Rufbereitschaft" },
        { value: 7, label: "Weihnachtsgeld" },
        { value: 8, label: "Tarifzulage" },
        { value: 9, label: "Prämie" },
        { value: 10, label: "Vorschuss" },
        { value: 13, label: "Urlaubsabgeltung" },
        { value: 15, label: "Schlüssel" },
        { value: 18, label: "Management Bonus" },
        { value: 19, label: "Fahrtgeld" },
        { value: 21, label: "Crew Bonus" },
        { value: 22, label: "Kaution Kleidung" },
        { value: 0, label: "Sonstiges" },
      ];
      let conditionJoinModes = [{label: "Und", value: true}, {label: "Oder", value: false}];

      // console.log("mdCollectiveContracts", view.mdCollectiveContracts);
      // console.log("storeCountries", view.storeCountries);
      let ccItems = view.mdCollectiveContracts.filter(i=> view.storeCountries.includes(i.country)).map(i => {
        let selected = false;
        if (entity && entity.details && entity.details.collectiveContractIds) {
          selected = entity.details.collectiveContractIds.includes(i.id);
        }
        if (view.storeCountries.length > 1) {
          return { label: i.name + " (" + view.countryEnumMap[i.country].key + ")", value: i.id, data: i, selected: selected };
        } else {
          return { label: i.name, value: i.id, data: i, selected: selected };
        }
      });
      // ccItems.push({label: "Kein Vertragsmodell", value: null});
      let spotItems = view.spots.filter(i => view.stores.find(s => s.id == i.storeId) !== undefined).map(i => {
        let selected = false;
        if (entity && entity.details && entity.details.spotIds) {
          selected = entity.details.spotIds.includes(i.id);
        }
        if (view.stores.length > 1) {
          return { label: i.name + " (" + (view.stores.find(s => s.id == i.storeId) ? view.stores.find(s => s.id == i.storeId).name : "") + ")", value: i.id, data: i, selected: selected };
        } else {
          return { label: i.name, value: i.id, data: i, selected: selected };
        }
      });
      // spotItems.push({label: "Keine Position", value: null});

      let shiftSpotItems = view.spots.filter(i => view.stores.find(s => s.id == i.storeId) !== undefined).map(i => {
        let selected = false;
        if (entity && entity.details && entity.details.shiftSpotIds) {
          selected = entity.details.shiftSpotIds.includes(i.id);
        }
        if (view.stores.length > 1) {
          return { label: i.name + " (" + (view.stores.find(s => s.id == i.storeId) ? view.stores.find(s => s.id == i.storeId).name : "") + ")", value: i.id, data: i, selected: selected };
        } else {
          return { label: i.name, value: i.id, data: i, selected: selected };
        }
      });
      // shiftSpotItems.push({label: "Keine Position", value: null});

      let fromLocationItems = view.stores.map(i => {
        let selected = false;
        if (entity && entity.details && entity.details.fromLocationIds) {
          selected = entity.details.fromLocationIds.includes(i.id);
        }
        return { label: i.name, value: i.id, data: i, selected: selected };
      });

      let contractTypeItems = Object.entries(view.contractTypeMap).map(([id, label]) => {
          return {
              label: label,
              value: id,
              selected: entity.details && entity.details.contractTypes ? Object.values(entity.details.contractTypes).includes(id) : false
          }
      });
      let employeeCategoryItems = Object.entries(view.employeeCategoryMap).map(([id, label]) => {
          return {
              label: label,
              value: id,
              selected: entity.details && entity.details.employeeCategories ? Object.values(entity.details.employeeCategories).includes(id) : false
          }
      });
      let employeeLevelItems = Object.entries(view.employeeLevelMap).map(([id, label]) => {
          return {
              label: label,
              value: id,
              selected: entity.details && entity.details.employeeLevels ? Object.values(entity.details.employeeLevels).includes(id) : false
          }
      });
      let employmentTypeItems = Object.entries(view.employmentTypeMap).map(([id, label]) => {
          return {
              label: label,
              value: id,
              selected: entity.details && entity.details.employmentTypes ? Object.values(entity.details.employmentTypes).includes(id) : false
          }
      });

      // fields from enum
      let monthlyFinancialStatementFields = [];
      view.mdWagetypes.forEach(wageType => {
        monthlyFinancialStatementFields.push({value: wageType.id, label: wageType.name});
      });

      // TODO:
      // Diese Ids (value) werden in der Datenbank (individualwagetypedetails) als enumWageTypeId gespeichert.
      // Das Feld enumWageTypeId ist aber eine Referenz auf enum_wagetypes.id (liste von wagetypes vom monatsabschluss)
      // daher passen die ids nicht zusammen (zB:
      //    id 16 = paidWorkTimeOnlyPositive
      //    id 17 = holidayTime
      //    id 40 = paidWorkTimeOnlyPositive
      //    id 41 = paidWorkTimeOnlyNegative
      //    id 42 = paidOverTimeOnlyPositive
      let customWageTypes = [];
      customWageTypes.push({value: 16, label: "Ausbez. Urlaub (T) * Durchschnittswerte"});//paidVacationTime
      customWageTypes.push({value: 17, label: "Ausbez. Urlaub (T)"});//paidVacationTime
      customWageTypes.push({value: 40, label: "Arbeitszeit mod. * 25% (nur +)"});//paidWorkTime
      customWageTypes.push({value: 41, label: "(IST - 169h) * 25% (nur +)"});//soll arbeitszeit ist workTimeRegular und produktive Arbeiszeit ist workTime
      customWageTypes.push({value: 42, label: "(IST - NAZ) * Stundenbonus (nur +)"});//konfigurierbare variablen
      customWageTypes.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        } else if (a.label < b.label) {
          return -1;
        }
        return 0;
      });

      let unavailabilityTypesList = JSON.parse(JSON.stringify(mdEmployeeAvailabilityTypes));
      let dynamicOvertimeUnavailabilityTypesList = JSON.parse(JSON.stringify(mdEmployeeAvailabilityTypes));
      if (entity.details && entity.details.dynamicOvertimeUnavailabilityTypes) {
        const selectedTypes = JSON.parse(entity.details.dynamicOvertimeUnavailabilityTypes).map(Number);  // Ensure values are treated as numbers
        dynamicOvertimeUnavailabilityTypesList.forEach(
          (at) => (at.selected = selectedTypes.includes(at.value))
        );
      }

      let isAbsenceType = entity.details && entity.details.workType === 1;
      let unavailabilityWithSubTypesSelected = entity.details && [2,4,8].includes(entity.details.unavailabilityType);
      let showUnavailabilitySubTypes = isAbsenceType && unavailabilityWithSubTypesSelected;
      if (entity.details && entity.details.employeeAvailabilitySubtypes && showUnavailabilitySubTypes) {
        const selectedTypes = JSON.parse(entity.details.employeeAvailabilitySubtypes).map(Number);  // Ensure values are treated as numbers
        mdEmployeeAvailabilitySubTypes.forEach((ast) => {
            ast.selected = selectedTypes.includes(ast.value);
          }
        );
      }

      if (entity.details && entity.details.enabledStores && entity.details.enabledStores != null) {
        const selectedStores = JSON.parse(entity.details.enabledStores).map(Number);  // Ensure values are treated as numbers
        mdStoresEnabled.forEach((es) => {
          es.selected = selectedStores.includes(es.value);
          }
        );
      }


      if (entity.details && entity.details.extraCostTypes) {
        const selectedTypes = JSON.parse(entity.details.extraCostTypes).map(Number);  // Ensure values are treated as numbers
        extraCostTypes.forEach(
          (ect) => (ect.selected = selectedTypes.includes(ect.value))  // Use includes on an array of numbers
        );
      }
      if (entity.details && entity.details.bonusExpenseTypes) {
        const selectedTypes = JSON.parse(entity.details.bonusExpenseTypes).map(Number);  // Ensure values are treated as numbers
        bonusExpenseTypes.forEach(
          (bet) => (bet.selected = selectedTypes.includes(bet.value))  // Use includes on an array of numbers
        );
      }

      if (entity.details) {
        entity.details.useHourlyWage = entity.details.useHourlyWage !== 0 && entity.details.useHourlyWage !== false;
        entity.details.useMaxWorkTime = entity.details.useMaxWorkTime !== 0 && entity.details.useMaxWorkTime !== false;
      }

      let dataEntries = [
        {
          label: "id",
          key: "id",
          hidden: true,
          value: entity.details ? entity.details.id : null,
        },
        {
          label: "wageTypeId",
          key: "wageTypeId",
          hidden: true,
          value: entity.id,
        },
        {
          // this will cause the workType dropdown to not open automatically
          label: "placeholder",
          key: "placeholder",
          hidden: false,
          styles: { 'opacity': '0', 'height': '0px' }
        },


        {
          label: 'Alle Standorte',
          key: 'enabledAllStores',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.enabledStores == null : false, // if null or all stores are selected
        },
        {
          label: "Standorte",
          key: "enabledStores",
          hidden: false,
          type: "dropdownsearch",
          styles: { width: "700px" },
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: mdStoresEnabled, // This is a map of all stores
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let enabledAllStores = datafill.entries.find((i) => i.key === "enabledAllStores");
            entryBeforeUpdate.hidden = enabledAllStores.value;
            //entryBeforeUpdate.dropdownSearchState.items = view.stores.map(store => ({value: store.id, selected: store.selected, label: `${store.name} (${store.city})`}));
            return entryBeforeUpdate;
          },
          value: entity.details && entity.details.enabledStores
            ? JSON.parse(entity.details.enabledStores)
            : null,
        },

        { divider: true },
        {
          label: "Typ",
          key: "workType",
          hidden: false,
          type: "dropdownsearch",
          styles: { width: "700px" },
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: workTypes,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: entity.details
              ? workTypes.find((wt) => wt.value === entity.details.workType)
              : null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: entity.details && workTypes.find((wt) => wt.value === entity.details.workType)
            ? workTypes.find((wt) => wt.value === entity.details.workType).value
            : null,
        },
        {
          label: "Abwesenheits Typ",
          key: "unavailabilityType",
          hidden: entity.details ? !(entity.details.workType === 1) : false,
          value: entity.details ? entity.details.unavailabilityType : null,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: unavailabilityTypesList,
            selectMultiple: false,
            selectedItems: [],
            selectedItem: entity.details ? unavailabilityTypesList.find(ut => ut.value === entity.details.unavailabilityType) : null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let unavailability =
              datafill.entries.find((i) => i.key === "workType").value === 1;

            entryBeforeUpdate.hidden = !unavailability;
            return entryBeforeUpdate;
          },
        },

        {
          label: "Detail: ",
          key: "unavailabilitySubTypes",
          // Default to hidden, will be shown based on computed logic below --> cant do it like this, because it will not show if we open
          // a popup, which is preselected with Abwesenheit Krankheit
          hidden: !showUnavailabilitySubTypes,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: entity.details ? mdEmployeeAvailabilitySubTypes.filter(
              (ast) => ast.parentType === entity.details.unavailabilityType
            ) : mdEmployeeAvailabilitySubTypes,
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            // Determine if the current workType is 'Abwesenheiten'
            let isAbsenceType = datafill.entries.find((i) => i.key === "workType").value === 1;

            // Determine if 'Krankheit', 'Sonstiges' or 'Lohnfortzahlung' is selected in unavailabilityTypes
            let unavailabilityWithSubTypesSelected = [2,4,8].includes(datafill.entries.find((i) => i.key === "unavailabilityType").value);

            // Show this block if workType is 'Abwesenheiten' and 'Krankheit' is selected
            let showUnavailabilitySubTypes = isAbsenceType && unavailabilityWithSubTypesSelected;
            entryBeforeUpdate.hidden = !showUnavailabilitySubTypes;

            // set items based on parentType
            entryBeforeUpdate.dropdownSearchState.items = mdEmployeeAvailabilitySubTypes.filter(
              (ast) => ast.parentType === datafill.entries.find((i) => i.key === "unavailabilityType").value
            );

            return entryBeforeUpdate;
          },
        },

        {
          label: 'Durchschnittstunden anwenden',
          key: 'useAverages',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.useAverageValues : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: 'Durchschnittlohn anwenden',
          key: 'useAverageWage',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.useAverageWage : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useAverages = datafill.entries.find((i) => i.key === "useAverages");
            entryBeforeUpdate.hidden = !useAverages.value;
            return entryBeforeUpdate;
          },
        },


        {
          label: 'Faktor mit "0" überschreiben',
          key: 'overwritePaymentWithZero',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.overwritePaymentWithZero : false,
        },
        {
          label: 'Auswertung: Lohnart in Stunden- und Tagessummen inkludieren',
          key: 'includeInSum',
          hidden: false,
          type: 'bool',
          value: entity.details != null ? entity.details.includeInSum : true, // default true
        },
        {
          label: 'Gültig von',
          key: 'validFrom',
          required: false,
          showDateField: entity.details != null ? entity.details.validFrom != null : false,
          hidden: false,
          type: 'date',
          value: entity.details != null && entity.details.validFrom != null ? this.$helpers.getMomentFromStringUTC(entity.details.validFrom, 'YYYY-MM-DD HH:mm').local() : this.$helpers.getMoment().startOf('day')
        },
        {
          label: 'Gültig bis',
          key: 'validTo',
          required: false,
          showDateField: entity.details != null ? entity.details.validTo != null : false,
          hidden: false,
          type: 'date',
          value: entity.details != null && entity.details.validTo != null ? this.$helpers.getMomentFromStringUTC(entity.details.validTo, 'YYYY-MM-DD HH:mm').local() : this.$helpers.getMoment().endOf('day')
        },

        { divider: true },
        {
          label: "Wochentage",
          key: "weekDays",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          flexGroup: false,
          type: "togglelist",
          toggleEntries: [
            {
              label: "Mo",
              value: 1,
              selected: entity.details ? entity.details.weekDays.includes(1) : true,
            },
            {
              label: "Di",
              value: 2,
              selected: entity.details ? entity.details.weekDays.includes(2) : true,
            },
            {
              label: "Mi",
              value: 3,
              selected: entity.details ? entity.details.weekDays.includes(3) : true,
            },
            {
              label: "Do",
              value: 4,
              selected: entity.details ? entity.details.weekDays.includes(4) : true,
            },
            {
              label: "Fr",
              value: 5,
              selected: entity.details ? entity.details.weekDays.includes(5) : true,
            },
            {
              label: "Sa",
              value: 6,
              selected: entity.details ? entity.details.weekDays.includes(6) : true,
            },
            {
              label: "So",
              value: 7,
              selected: entity.details ? entity.details.weekDays.includes(7) : true,
            },
          ],
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: "Nur Feiertage",
          key: "holidays",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          type: "bool",
          value: entity.details ? entity.details.holidays : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: "Uhrzeit Von",
          key: "timeFrom",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          type: "timeframe",
          value: entity.details
            ? view.$helpers.getMomentFromStringTZ(entity.details.timeFrom, "HH:mm")
            : this.$helpers.getMomentTZ().hours(0).minutes(0),
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: "Uhrzeit Bis",
          key: "timeTo",
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          type: "timeframe",
          value: entity.details
            ? view.$helpers.getMomentFromStringTZ(entity.details.timeTo, "HH:mm")
            : this.$helpers.getMomentTZ().hours(24).minutes(0),
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {
          label: 'Schicht beginnt innerhalb des Zeitraums',
          key: 'startsWithin',
          hidden: entity.details ? entity.details.workType != 0 : true,
          type: 'bool',
          value: entity.details != null && entity.details.startsWithin != null ? entity.details.startsWithin : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }
        },
        {
          divider: true,
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },
        },
        {label: 'Min. Arbeitszeit', key: 'minWorkTime', hidden: false, type: 'slider', value: entity.details != null ? entity.details.minWorkTime : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 12 *60, value: entity.details != null ? entity.details.minWorkTime : 0, step: 15, DisplayLabel: (val) => {

              return (val/60).toFixed(2)+"h";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },},
        {label: 'Max. Arbeitszeit anwenden', key: 'useMaxWorkTime', hidden: false, type: 'bool', value: entity.details != null ? entity.details.useMaxWorkTime : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1);
            return entryBeforeUpdate;
          },},
        {label: 'Max. Arbeitszeit', key: 'maxWorkTime', hidden: entity.details != null ? !entity.details.useMaxWorkTime : true, type: 'slider', value: entity.details != null ? (entity.details.maxWorkTime != null ?  entity.details.maxWorkTime : 0): 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 24 *60, value: entity.details != null ? (entity.details.maxWorkTime != null ?  entity.details.maxWorkTime : 0) : 0, step: 15, DisplayLabel: (val) => {

              return (val/60).toFixed(2)+"h";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useMaxWorkTime = datafill.entries.find((i) => i.key == "useMaxWorkTime")
              .value;

            if (useMaxWorkTime) {
              entryBeforeUpdate.hidden = false;
            } else {
              entryBeforeUpdate.slider.value = 0;
              entryBeforeUpdate.value = 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }},
        {divider: true,
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1) || (entity.details.workType === 1 && entity.details.useAverages) : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let useAverages = datafill.entries.find((i) => i.key === "useAverages");
            entryBeforeUpdate.hidden = !(worktype === 0 || worktype === 1) || (worktype === 1 && useAverages.value);
            return entryBeforeUpdate;
          },},
        {label: 'Bonus anwenden', key: 'useBonus',
          hidden: entity.details ? !(entity.details.workType === 0 || entity.details.workType === 1): false,
          type: 'dropdownsearch', value: entity.details ? entity.details.useBonus : bonusTypes[0].value,
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: '',
            items: bonusTypes,
            selectMultiple: false,
            selectedItem: entity.details ? bonusTypes.find(bt => bt.value === entity.details.useBonus) : bonusTypes[0],
            selectFirst: false,
            styleLight: true,
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let useAverages = datafill.entries.find((i) => i.key === "useAverages");
            if(!(worktype === 0 || worktype === 1)  || (worktype === 1 && useAverages.value)){
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.value = 0;
              entryBeforeUpdate.dropdownSearchState.selectedItem = bonusTypes[0];
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          },},
        {label: 'Stundenlohn anwenden', key: 'useHourlyWage',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.bonusMode === 2 || entity.details.bonusMode === 4) : true,
          type: 'bool', value: entity.details != null ? entity.details.useHourlyWage : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || mode == 2 || mode == 4){
              entryBeforeUpdate.hidden = true;
              entryBeforeUpdate.value = false;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          } },
        {label: 'Stundenlohn Bonus', key: 'hourlyWagePercentage',
          hidden: entity.details ? entity.details.useBonus === 0 || (!entity.details.useHourlyWage || entity.details.bonusMode === 2 || entity.details.bonusMode === 4) : true,
          type: 'slider', value: entity.details != null ? entity.details.hourlyWagePercentage : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 400, value: entity.details != null ? entity.details.hourlyWagePercentage : 0, step: 1, DisplayLabel: (val) => {

              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useHourlyWage = datafill.entries.find(i => i.key == 'useHourlyWage').value;
            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || !useHourlyWage || mode == 2 || mode == 4){

              entryBeforeUpdate.slider.value = entity.details != null ? entity.details.hourlyWagePercentage : 0;
              entryBeforeUpdate.value = entity.details != null ? entity.details.hourlyWagePercentage : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {label: 'Stunden Bonus', key: 'bonusHour',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.useHourlyWage || entity.details.bonusMode === 2 || entity.details.bonusMode === 4) : true,
          type: 'slider', value: entity.details != null ? entity.details.bonusHour : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100*100, value: entity.details != null ? entity.details.bonusHour : 0, step: 1, DisplayLabel: (val) => {

              return (val/100.0).toFixed(2)+"€";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useHourlyWage = datafill.entries.find(i => i.key == 'useHourlyWage').value;
            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || useHourlyWage || mode == 2 || mode == 4){

              entryBeforeUpdate.slider.value = entity.details != null ? entity.details.bonusHour : 0;
              entryBeforeUpdate.value = entity.details != null ? entity.details.bonusHour : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Pauschal Bonus', key: 'bonusShift',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.bonusMode === 3 || entity.details.bonusMode === 5) : true,
          type: 'slider', value: entity.details != null ? entity.details.bonusShift : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100*100, value: entity.details != null ? entity.details.bonusShift : 0, step: 1, DisplayLabel: (val) => {

              return (val/100.0).toFixed(2)+"€";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {

            let mode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || mode == 3 || mode == 5){

              entryBeforeUpdate.slider.value = entity.details != null ? entity.details.bonusShift : 0;
              entryBeforeUpdate.value = entity.details != null ? entity.details.bonusShift : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Berechnungs Modus', key: 'bonusMode',
          hidden: entity.details ? entity.details.useBonus === 0 : true, type: 'dropdownsearch',
          value: entity.details ? entity.details.bonusMode : bonusModeItems.filter(bm => { if(entity.details && entity.details.workType != 0) return bm.alwaysShow; else return true;})[0].value, dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: bonusModeItems.filter(bm => { if(entity.details && entity.details.workType != 0) return bm.alwaysShow; else return true;}),
            selectMultiple: false,
            selectedItem: entity.details ? bonusModeItems.find(bm => bm.value === entity.details.bonusMode) : bonusModeItems.filter(bm => { if(entity.details && entity.details.workType != 0) return bm.alwaysShow; else return true;})[0],
            selectFirst: false,
            styleLight: true,
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype0 = datafill.entries.find((i) => i.key === "workType").value == 0;
            let worktype1 = datafill.entries.find((i) => i.key === "workType").value == 1;
            let hiddenWorkTypes = !(worktype0 || worktype1);
            if(hiddenWorkTypes || bonus == 0){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
              let selectedItem = JSON.parse(JSON.stringify(entryBeforeUpdate.dropdownSearchState.items.find(bm => bm.selected)));
              if (worktype0) {
                entryBeforeUpdate.dropdownSearchState.items = bonusModeItems.map(bm => ({value: bm.value, label: bm.label, alwaysShow: bm.alwaysShow, selected: false}));
                entryBeforeUpdate.dropdownSearchState.items.find(bm => bm.value === selectedItem.value).selected = true;
                entryBeforeUpdate.dropdownSearchState.selectedItem = entryBeforeUpdate.dropdownSearchState.items.find(bm => bm.value === selectedItem.value);
              } else {
                entryBeforeUpdate.dropdownSearchState.items = bonusModeItems.filter(bm => bm.alwaysShow).map(bm => ({value: bm.value, label: bm.label, alwaysShow: bm.alwaysShow, selected: false}));
                entryBeforeUpdate.dropdownSearchState.items.find(bm => bm.value === selectedItem.value) ?
                  entryBeforeUpdate.dropdownSearchState.items.find(bm => bm.value === selectedItem.value).selected = true :
                  entryBeforeUpdate.dropdownSearchState.items[0].selected = true;
                entryBeforeUpdate.dropdownSearchState.selectedItem = entryBeforeUpdate.dropdownSearchState.items.find(bm => bm.selected);
              }
            }
            return entryBeforeUpdate;
          }
          },
        {label: 'Pauschal Grenze', key: 'bonusCutoff',
          hidden: entity.details ? entity.details.useBonus === 0 || (entity.details.bonusMode === 2 || entity.details.bonusMode === 3) : true,
          type: 'slider', value: entity.details != null ? entity.details.bonusCutoff : 50,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100, value: entity.details != null ? entity.details.bonusCutoff : 50, step: 1, DisplayLabel: (val) => {

              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let bonusMode = datafill.entries.find(i => i.key == 'bonusMode').value;
            let bonus = datafill.entries.find(i => i.key == 'useBonus').value;
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let hiddenWorkTypes = !(worktype === 0 || worktype === 1);
            if(hiddenWorkTypes || bonus == 0 || bonusMode == 2 || bonusMode == 3){

              entryBeforeUpdate.slider.value = 50;
              entryBeforeUpdate.value = 50;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },



        {
          label: 'Positives Vorzeichen',
          key: 'invertSignWorktype2',
          hidden: entity.details ? !(entity.details.workType === 2) : true,
          type: 'bool',
          value: entity.details != null ? entity.details.invertSign : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 2)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: 'Negatives Vorzeichen',
          key: 'invertSignWorktype3',
          hidden: entity.details ? !(entity.details.workType === 3) : true,
          type: 'bool',
          value: entity.details != null ? entity.details.invertSign : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 3)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },



        {label: 'Andere Kosten Typ', key: 'extraCostTypes',
          hidden: entity.details ? !(entity.details.workType === 2) : true,
          type: 'dropdownsearch',
          value: entity.details ? (entity.details.extraCostTypes ? JSON.parse(entity.details.extraCostTypes) : []) : extraCostTypes[0].value,
          dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: extraCostTypes,
            selectMultiple: true,
            selectedItem: null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 2)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Andere Kosten Auszahlung', key: 'extraCostPercentage',
          hidden: entity.details ? !(entity.details.workType === 2) : true,
          type: 'slider', value: entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0,
          slider: {hideMin: true, hideMax: true, min: 0, max: 400, value: entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0, step: 1, DisplayLabel: (val) => {

              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 2)){

              entryBeforeUpdate.slider.value = entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0;
              entryBeforeUpdate.value = entity.details && entity.details.extraCostPercentage ? entity.details.extraCostPercentage : 0;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {label: 'Bonuszahlung Typ', key: 'bonusExpenseTypes',
          hidden: entity.details ? !(entity.details.workType === 3) : true, type: 'dropdownsearch',
          value: entity.details ? entity.details.bonusExpenseTypes : bonusExpenseTypes[0].value, dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: bonusExpenseTypes,
            selectMultiple: true,
            selectedItem: null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 3)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Lohnart Monatsabschluss', key: 'mdWageTypeId',
          hidden: entity.details ? !(entity.details.workType === 5) : true, type: 'dropdownsearch',
          value: entity.details && entity.details.mdWageTypeId && monthlyFinancialStatementFields.find(f => { return f.value === entity.details.mdWageTypeId})
            ? monthlyFinancialStatementFields.find(f => { return f.value === entity.details.mdWageTypeId}).value : null,
          dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: monthlyFinancialStatementFields,
            selectMultiple: false,
            selectedItem: entity.details && entity.details.mdWageTypeId != null ?
            monthlyFinancialStatementFields.find(f => { return f.value === entity.details.mdWageTypeId}) : null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 5)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Lohnart Monatssummen', key: 'enumWageTypeId',
          hidden: entity.details ? !(entity.details.workType === 4) : true, type: 'dropdownsearch',
          value: entity.details && entity.details.enumWageTypeId && customWageTypes.find(f => { return f.value === entity.details.enumWageTypeId})
            ? customWageTypes.find(f => { return f.value === entity.details.enumWageTypeId}).value : null,
          dropdownSearchState:{
            toggled: false,
            displayedItems: [],
            input: '',
            items: customWageTypes,
            selectMultiple: false,
            selectedItem: entity.details && entity.details.enumWageTypeId != null ?
              customWageTypes.find(f => { return f.value === entity.details.enumWageTypeId}) : null,
            selectFirst: false,
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            if(!(worktype === 4)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }
            return entryBeforeUpdate;
          }
        },
        // {
        //   divider: true,
        //   hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
        //   computed: (datafill, lastVal, entryBeforeUpdate) => {
        //     let worktype = datafill.entries.find((i) => i.key === "workType").value;
        //     let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
        //     entryBeforeUpdate.hidden = !(worktype === 4 && enumWageTypeId === 42);
        //     return entryBeforeUpdate;
        //   },
        // },
        {
          label: 'Normalarbeitszeit (NAZ) aus Mitarbeiterverträgen übernehmen',
          key: 'dynamicOvertimeUseContractHours',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: "bool",
          value: entity.details ? entity.details.dynamicOvertimeUseContractHours : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          },
        },
        {label: 'Normalarbeitszeit (NAZ)', key: 'dynamicOvertimeWorkTimeRegular',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42 && !entity.details.dynamicOvertimeUseContractHours) : true,
          type: 'slider', value: entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169,
          slider: {hideMin: true, hideMax: true, min: 100, max: 200, value: entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169, step: 1, DisplayLabel: (val) => {
              return val+"h";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            let useContractHours = datafill.entries.find((i) => i.key === "dynamicOvertimeUseContractHours").value;
            if(!(worktype === 4 && enumWageTypeId === 42 && !useContractHours)){

              entryBeforeUpdate.slider.value = entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169;
              entryBeforeUpdate.value = entity.details && entity.details.dynamicOvertimeWorkTimeRegular ? entity.details.dynamicOvertimeWorkTimeRegular : 169;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {label: 'Stundenbonus', key: 'dynamicOvertimeBonus',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: 'slider', value: entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25,
          slider: {hideMin: true, hideMax: true, min: 0, max: 100, value: entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25, step: 1, DisplayLabel: (val) => {
              return val+"%";
            }},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){

              entryBeforeUpdate.slider.value = entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25;
              entryBeforeUpdate.value = entity.details && entity.details.dynamicOvertimeBonus ? entity.details.dynamicOvertimeBonus : 25;
              entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
              entryBeforeUpdate.hidden = true;

            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          }
        },
        {
          label: 'Produktive Arbeitszeit in IST inkludieren',
          key: 'dynamicOvertimeIncludeProductiveWorktime',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: "bool",
          value: true,
          disabled: true,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          },
        },
        {
          label: 'Abwesenheits Typen in IST inkludieren',
          key: 'dynamicOvertimeUnavailabilityTypes',
          hidden: entity.details ? !(entity.details.workType === 4 && entity.details.enumWageTypeId === 42) : true,
          type: "dropdownsearch",
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: dynamicOvertimeUnavailabilityTypesList,
            selectMultiple: true,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let worktype = datafill.entries.find((i) => i.key === "workType").value;
            let enumWageTypeId = datafill.entries.find((i) => i.key === "enumWageTypeId").value;
            if(!(worktype === 4 && enumWageTypeId === 42)){
              entryBeforeUpdate.hidden = true;
            } else {
              entryBeforeUpdate.hidden = false;
            }

            return entryBeforeUpdate;
          },
        },
        //{label: 'Sortierungs Priorität', key: 'priority', hidden: false, type: 'slider', value: wt.sorting, slider: {value: wt.sorting, min: 1, max: 100}},
        { // divider
          divider: true,
          hidden: entity.details ? entity.details.workType != 0 : true,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }
        },
        {
          label: 'Exklusive Gruppe anwenden', key: 'useExclusiveGroup',
          hidden: entity.details ? entity.details.workType != 0 : true,
          type: 'bool',
          value: entity.details != null ? entity.details.exclusiveGroup != null : false,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.value = false;
                entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Exklusive Gruppe', key: 'exclusiveGroup', hidden: entity.details != null ? (entity.details.exclusiveGroup == null || entity.details.workType != 0) : true, type: 'slider', value: entity.details != null ? (entity.details.exclusiveGroup != null ?  entity.details.exclusiveGroup : 1): 1,
          slider: {hideMin: true, hideMax: true, min: 1, max: 10, value: entity.details != null ? (entity.details.exclusiveGroup != null ?  entity.details.exclusiveGroup : 1) : 1,
            step: 1, DisplayLabel: (val) => { return val;}, inputControl: true, sliderInput:(x) => {return x}},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useExclusiveGroup = datafill.entries.find(i => i.key == 'useExclusiveGroup').value;
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(useExclusiveGroup && workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.slider.value = 1;
                entryBeforeUpdate.value = 1;
                entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
                entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Priorität in Gruppe', key: 'priority', hidden: entity.details != null ? (entity.details.exclusiveGroup == null || entity.details.workType != 0) : true, type: 'slider', value: entity.details != null ? (entity.details.exclusiveGroup != null ?  entity.details.priority : 1): 1,
          slider: {hideMin: true, hideMax: true, min: 1, max: 10, value: entity.details != null ? (entity.details.exclusiveGroup != null ?  entity.details.priority : 1) : 1,
            step: 1, DisplayLabel: (val) => {return val;}, inputControl: true, sliderInput:(x) => {return x}},
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let useExclusiveGroup = datafill.entries.find(i => i.key == 'useExclusiveGroup').value;
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(useExclusiveGroup && workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.slider.value = 1;
                entryBeforeUpdate.value = 1;
                entryBeforeUpdate.slider = Object.assign({}, entryBeforeUpdate.slider);
                entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }
        },
        { // divider
          divider: true,
          hidden: entity.details ? entity.details.workType != 0 : true,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.hidden = true;
            }
            return entryBeforeUpdate;
          }
        },
        {label: 'Vertragsmodell', key: 'collectiveContractIds', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.collectiveContractId : null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: ccItems,
                selectMultiple: true,
                selectedItem: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Mitarbeiter Position', key: 'spotIds', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.spotIds : null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: spotItems,
                selectMultiple: true,
                selectedItem: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Schicht Position', key: 'shiftSpotIds', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.shiftSpotIds : null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: shiftSpotItems,
                selectMultiple: true,
                selectedItem: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Schicht Standort', key: 'fromLocationIds', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.fromLocationIds : null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: fromLocationItems,
                selectMultiple: true,
                selectedItem: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Vertragstypen', key: 'contractTypes', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.contractTypes: null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: contractTypeItems,
                selectMultiple: true,
                selectedItems: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Mitarbeiter Kategorien', key: 'employeeCategories', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.employeeCategories: null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: employeeCategoryItems,
                selectMultiple: true,
                selectedItems: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Mitarbeiter Levels', key: 'employeeLevels', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.employeeLevels: null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: employeeLevelItems,
                selectMultiple: true,
                selectedItems: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Anstellungsart', key: 'employmentTypes', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.employmentTypes: null,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: employmentTypeItems,
                selectMultiple: true,
                selectedItems: [],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.dropdownSearchState.items.forEach((item) => {
                      item.selected = false;
                  });
              }
              return entryBeforeUpdate;
            }
        },
        {label: 'Bedingung Verknüpfung', key: 'conditionJoin', hidden: entity.details ? entity.details.workType != 0 : true, type: 'dropdownsearch', value: entity.details ? entity.details.conditionJoin: false,
            dropdownSearchState:{
                toggled: false,
                displayedItems: [],
                input: '',
                items: conditionJoinModes,
                selectMultiple: false,
                selectedItem: entity.details != null && entity.details.conditionJoin != null ? conditionJoinModes.find(c => c.value == entity.details.conditionJoin) : conditionJoinModes[1],
                selectFirst: false,
                styleLight: true,
            },
            computed: (datafill, lastVal, entryBeforeUpdate) => {
              let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
              if(workType0){
                  entryBeforeUpdate.hidden = false;
              } else {
                  entryBeforeUpdate.hidden = true;
                  entryBeforeUpdate.value = false;
              }
              return entryBeforeUpdate;
            }
        },
        {
          label: 'Auch auf Jugendliche anwenden',
          key: 'applyToUnderage',
          hidden: entity.details ? entity.details.workType != 0 : true,
          type: 'bool',
          value: entity.details != null && entity.details.applyToUnderage != null ? entity.details.applyToUnderage : true,
          computed: (datafill, lastVal, entryBeforeUpdate) => {
            let workType0 = datafill.entries.find(i => i.key == 'workType').value == 0;
            if(workType0){
                entryBeforeUpdate.hidden = false;
            } else {
                entryBeforeUpdate.hidden = true;
                entryBeforeUpdate.value = true;
            }
            return entryBeforeUpdate;
          }
        },
        { flexDivider: true, styles: { "margin-bottom": "23px" } },
      ];
      this.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (eEntity) {
            view.editDetailsEntity(eEntity)
              .then((response) => {
                view.$helpers.success("Erfolg", "Lohnart Details geändert");
                view.refresh(true);
              })
              .catch(function (error) {
                view.$helpers.error("Fehler", "Lohnart Details konnten nicht geändert werden");
                console.log(error);
              });
          },
          errorCheck: {},
        },
        header: "Lohnart Details bearbeiten - " + entity.name,
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openImportMdBonusPaymentSettings() {
      let view = this;
      view.showModal('modal-loading');
      view.loadMdBonusPaymentSettings()
      .then((response) => {
        view.hideModal('modal-loading');

        let importedBonusPaymentSettings = response.map((bps) => {
          let storeName = view.stores.find(store => store.id == bps.storeId) ? view.stores.find(store => store.id == bps.storeId).name : "Unbekannter Store";
          return {
            value: bps.id,
            label: bps.name + " (" + storeName + ")"
          };
        });

        let dataEntries = [
          {
            label: "Bonus Einstellung",
            key: "bonusPaymentSetting",
            hidden: false,
            type: "dropdownsearch",
            styles: { width: "500px" },
            dropdownSearchState: {
              toggled: false,
              displayedItems: [],
              input: "",
              items: importedBonusPaymentSettings, // This is a map of all stores
              selectMultiple: false,
              selectedItems: [],
              selectedItem: null,
              onceSelected: function ($event, item, searchbar) {},
              styleLight: true,
              placeholder: "Bitte auswählen...",
            },
            value: null,
          },
          {
            label: 'Auf alle Standorte anwenden',
            key: 'enabledAllStores',
            hidden: false,
            type: 'bool',
            value: false,
          },
          {
            label: "Nummer",
            key: "numberString",
            hidden: false,
            type: "numeric",
            value: 0,
            min: 0,
            max: 65535,
            styles: { width: "500px" },
          },
          {
            label: "Name",
            key: "name",
            hidden: false,
            type: "text",
            value: "",
            length: 128,
            styles: { width: "500px" },
          },

        ];


        view.$helpers.OpenPopup({
          elRelative: null,
          type: "datafill",
          pointerDirection: 0,
          componentState: {
            entries: dataEntries,
            selectCallback: function (entity) {
              view.createWageTypeAndDetailsFromBonusPaymentSetting(entity);
            },
            errorCheck: {
              bonusPaymentSetting: (entries) => {
                if (entries.bonusPaymentSetting == null) {
                  let key = "bonusPaymentSetting";
                  let label = dataEntries.find((f) => f.key === key) ? dataEntries.find((f) => f.key === key).label : "";
                  return {
                    key: key,
                    label: label,
                    type: "error",
                    text: "Bitte auswählen",
                  };
                } else {
                  return null;
                }
              },
              // numberString: (entries) => {
              //   if (entries.numberString == undefined || view.individualWageTypes.find((f) => f.numberString+"" === entries.numberString+"")) {
              //     let key = "numberString";
              //     let label = dataEntries.find((f) => f.key === key) ? dataEntries.find((f) => f.key === key).label : "";
              //     return {
              //       key: key,
              //       label: label,
              //       type: "error",
              //       text: "Wird bereits verwendet",
              //     };
              //   } else {
              //     return null;
              //   }
              // },
              name: (entries) => {
                let key = "name";
                let label = dataEntries.find((f) => f.key === key) ? dataEntries.find((f) => f.key === key).label : "";
                if (entries.name == undefined || entries.name == "") {
                  return {
                    key: key,
                    label: label,
                    type: "error",
                    text: "Bitte ausfüllen",
                  };
                } else if (view.individualWageTypes.find((f) => f.name.toLowerCase() === entries.name.toLowerCase())) {
                  return {
                    key: key,
                    label: label,
                    type: "error",
                    text: "Wird bereits verwendet",
                  };
                } else {
                  return null;
                }
              },
            },
          },
          header: "Neue Lohnart anlegen",
          remainAfterSelect: false,
          centered: true,
          expanded: true,
        });
      })
      .catch((error) => {
        view.hideModal('modal-loading');
        view.$helpers.error("Fehler", "Bonuszahlungseinstellungen konnten nicht geladen werden");
        console.log(error);
      });
    },
    openImportBonusPaymentSettingsFromCompanySelect() {
      let view = this;

      let dataEntries = [
        {
          label: "Company",
          key: "companyId",
          hidden: false,
          type: "dropdownsearch",
          styles: { width: "500px" },
          dropdownSearchState: {
            toggled: false,
            displayedItems: [],
            input: "",
            items: Object.values(this.companyArray).filter(c => c.id !== this.companyId).map((company) => {
              return {
                value: company.id,
                label: company.name,
              };
            }),
            selectMultiple: false,
            selectedItems: [],
            selectedItem: null,
            onceSelected: function ($event, item, searchbar) {},
            styleLight: true,
            placeholder: "Bitte auswählen...",
          },
          value: null,
        },
      ];


      view.$helpers.OpenPopup({
        elRelative: null,
        type: "datafill",
        pointerDirection: 0,
        componentState: {
          entries: dataEntries,
          selectCallback: function (entity) {
            view.openImportBonusPaymentSettingsFromCompany(entity.companyId);
          },
          errorCheck: {
            companyId: (entries) => {
              if (entries.companyId == null) {
                let key = "companyId";
                let label = dataEntries.find((f) => f.key === key) ? dataEntries.find((f) => f.key === key).label : "";
                return {
                  key: key,
                  label: label,
                  type: "error",
                  text: "Bitte auswählen",
                };
              } else {
                return null;
              }
            }
          },
        },
        header: "Company für Import auswählen",
        remainAfterSelect: false,
        centered: true,
        expanded: true,
      });
    },
    openImportBonusPaymentSettingsFromCompany(companyId) {
      let view = this;

      view.showModal('modal-loading');
      view.loadBonusPaymentSettingsFromCompany(companyId)
      .then((response) => {
        view.hideModal('modal-loading');

        // console.log(response);

        let importedBonusPaymentSettings = response.map((bps) => {
          let storeName = view.stores.find(store => store.id == bps.storeId) ? view.stores.find(store => store.id == bps.storeId).name : "Unbekannter Store";
          return {
            value: bps.id,
            label: bps.name
          };
        });

        let dataEntries = [
          {
            label: "Bonus Einstellung",
            key: "bonusPaymentSetting",
            hidden: false,
            type: "dropdownsearch",
            styles: { width: "500px" },
            dropdownSearchState: {
              toggled: false,
              displayedItems: [],
              input: "",
              items: importedBonusPaymentSettings, // This is a map of all stores
              selectMultiple: true,
              selectedItems: [],
              selectedItem: null,
              onceSelected: function ($event, item, searchbar) {},
              styleLight: true,
              placeholder: "Bitte auswählen...",
            },
            value: null,
          },

        ];


        view.$helpers.OpenPopup({
          elRelative: null,
          type: "datafill",
          pointerDirection: 0,
          componentState: {
            entries: dataEntries,
            selectCallback: function (entity) {
              view.cloneIndividualWageTypes(entity.bonusPaymentSetting);
            },
            errorCheck: {
              bonusPaymentSetting: (entries) => {
                // console.log(entries.bonusPaymentSetting);
                if (entries.bonusPaymentSetting.length == 0) {
                  let key = "bonusPaymentSetting";
                  let label = dataEntries.find((f) => f.key === key) ? dataEntries.find((f) => f.key === key).label : "";
                  return {
                    key: key,
                    label: label,
                    type: "error",
                    text: "Bitte auswählen",
                  };
                // } else if (view.individualWageTypes.find((f) => f.name.toLowerCase() === entries.name.toLowerCase())) {
                //   return {
                //     key: key,
                //     label: label,
                //     type: "error",
                //     text: "Wird bereits verwendet",
                //   };
                } else {
                  let selectedBonusPaymentSettingNames = entries.bonusPaymentSetting.map((bpsId) => {
                    return importedBonusPaymentSettings.find((bps) => bps.value == bpsId).label;
                  });
                  if (view.individualWageTypes.find((f) => selectedBonusPaymentSettingNames.includes(f.name))) {
                    // console.log(view.individualWageTypes.find((f) => selectedBonusPaymentSettingNames.includes(f.name)));
                    let key = "bonusPaymentSetting";
                    let label = view.individualWageTypes.find((f) => selectedBonusPaymentSettingNames.includes(f.name)).name;
                    return {
                      key: key,
                      label: label,
                      type: "error",
                      text: "Wird bereits verwendet",
                    };
                  }
                  return null;
                }
              },
            },
          },
          header: "Lohnart importieren",
          remainAfterSelect: false,
          centered: true,
          expanded: true,
        });
      })
      .catch((error) => {
        view.hideModal('modal-loading');
        view.$helpers.error("Fehler", "Bonuszahlungseinstellungen konnten nicht geladen werden");
        console.log(error);
      });
    },


    createIndividualWageType(newWageType) {
      let view = this;
      view.sendCreateIndividualWageType(newWageType)
        .then((response) => {
          view.$helpers.success(
            "Lohnart erstellt",
            "Lohnart erstellt"
          );
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Lohnart konnte nicht erstellt werden");
          console.log(error);
        });
    },
    sendCreateIndividualWageType(newWageType) {
      let view = this;
      let url = "/api/sec/individualWageTypes";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes"
      );
      let payload = {
        companyId: this.$store.state.companyId,
        numberString: newWageType.numberString,
        name: newWageType.name,
        priority: 0, //newWageType.priority,
      };
      return this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      });
    },
    editEntity(editWageType) {
      let view = this;
      let url = "/api/sec/individualWageTypes/" + editWageType.id;
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes/" + editWageType.id
      );
      let payload = {
        companyId: this.$store.state.companyId,
        numberString: editWageType.numberString,
        name: editWageType.name,
        priority: 0, //editWageType.priority,
      };
      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success(
            "Beschäftigungsmodell geändert",
            "Beschäftigungsmodell geändert"
          );
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Beschäftigungsmodell konnte nicht geändert werden");
          console.log(error);
        });
    },
    editDetailsEntity(entity) {
      console.log("entity", entity);
      let view = this;

      let url = "";
      let method = "";
      if (entity.id) {
        url = "/api/sec/individualWageTypeDetails/" + entity.id;
        url = this.$helpers.appendParam(
          "companyId",
          this.$store.state.companyId,
          url,
          "/api/sec/individualWageTypeDetails/" + entity.id
        );
        method = "put";
      } else {
        url = "/api/sec/individualWageTypeDetails";
        url = this.$helpers.appendParam(
          "companyId",
          this.$store.state.companyId,
          url,
          "/api/sec/individualWageTypeDetails"
        );
        method = "post";
      }

      if (!entity.useExclusiveGroup) {
        entity.exclusiveGroup = null;
        entity.priority = null;
      }

      entity.companyId = this.$store.state.companyId;
      entity.timeFrom = entity.timeFrom.format("HH:mm");
      entity.timeTo = entity.timeTo.format("HH:mm");

      let payload = entity;

      return this.axios({
        method: method,
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      });
    },
    createWageTypeAndDetailsFromBonusPaymentSetting(entity) {
      let view = this;

      let bonusPaymentSetting = view.mdBonusPaymentSettings.find(bps => bps.id == entity.bonusPaymentSetting);
      if (!bonusPaymentSetting) {
        view.$helpers.error("Fehler", "Bonuszahlungseinstellung konnte nicht übernommen werden");
        return;
      }

      // build wage type details from bonusPaymentSetting
      let wageType = {
        numberString: entity.numberString,
        name: entity.name,
      };
      let wageTypeDetail = {
        workType: 0,
        weekDays: bonusPaymentSetting.weekDays,
        holidays: bonusPaymentSetting.onlyHoliday,
        timeFrom: view.$helpers.getMomentFromStringTZ(bonusPaymentSetting.bonusFrom, "HH:mm"),
        timeTo: view.$helpers.getMomentFromStringTZ(bonusPaymentSetting.bonusTo, "HH:mm"),
        useBonus: 2,
        useHourlyWage: bonusPaymentSetting.useHourlyWage,
        hourlyWagePercentage: bonusPaymentSetting.hourlyWagePercentage,
        bonusHour: bonusPaymentSetting.bonusHour,
        bonusShift: bonusPaymentSetting.bonusShift,
        bonusMode: bonusPaymentSetting.bonusMode,
        bonusCutoff: bonusPaymentSetting.bonusCutoff,
        minWorkTime: bonusPaymentSetting.minWorkTime,
        useMaxWorkTime: bonusPaymentSetting.maxWorkTime != null,
        maxWorkTime: bonusPaymentSetting.maxWorkTime ? bonusPaymentSetting.maxWorkTime : 0,
        enabledAllStores: entity.enabledAllStores,
        enabledStores: [bonusPaymentSetting.storeId], // set store of current setting if not enabled for all stores
        includeInSum: 0,
        validFrom: bonusPaymentSetting.from,
        validTo: bonusPaymentSetting.to,
        useExclusiveGroup: bonusPaymentSetting.exclusiveGroup != null && bonusPaymentSetting.priority != null,
        exclusiveGroup: bonusPaymentSetting.exclusiveGroup,
        priority: bonusPaymentSetting.priority,
        collectiveContractIds: bonusPaymentSetting.collectiveContractId ? [bonusPaymentSetting.collectiveContractId] : [],
        spotIds: bonusPaymentSetting.spotId ? [bonusPaymentSetting.spotId] : [],
        shiftSpotIds: bonusPaymentSetting.shiftSpotId ? [bonusPaymentSetting.shiftSpotId] : [],
        fromLocationIds: bonusPaymentSetting.fromLocationId ? [bonusPaymentSetting.fromLocationId] : [],
        contractTypes: bonusPaymentSetting.contractTypes,
        employeeCategories: bonusPaymentSetting.employeeCategories,
        employeeLevels: bonusPaymentSetting.employeeLevels,
        employmentTypes: bonusPaymentSetting.employmentTypes,
        conditionJoin: bonusPaymentSetting.conditionJoin,
        applyToUnderage: bonusPaymentSetting.applyToUnderage,
        startsWithin: bonusPaymentSetting.startsWithin,
        extraCostPercentage: 0,
        useAverages: 0,
        useAverageWage: 0,
        overwritePaymentWithZero: 0,
        dynamicOvertimeUseContractHours: 0,
        unavailabilitySubTypes: [],
        dynamicOvertimeUnavailabilityTypes: [],
        extraCostTypes: [],
        bonusExpenseTypes: [],
      };

      // console.log(wageTypeDetail);

      view.sendCreateIndividualWageType(wageType)
        .then((response) => {
          // console.log(response.data);
          wageTypeDetail.wageTypeId = response.data.id;
          // console.log(wageTypeDetail);
          view.editDetailsEntity(wageTypeDetail)
            .then((response) => {
              view.$helpers.success("Erfolg", "Bonuszahlungseinstellung wurde erfolgreich übernommen");
              view.refresh(true);
            })
            .catch((error) => {
              view.$helpers.error("Fehler", "Lohnart Details konnten nicht angelegt werden");
              console.log(error);
            });
        })
        .catch((error) => {
          view.$helpers.error("Fehler", "Lohnart konnte nicht angelegt werden");
          console.log(error);
        });
    },
    cloneIndividualWageTypes(iwtIds) {
      console.log("cloning iwtIds... ",  iwtIds);

      let view = this;
      let url = "/api/sec/individualWageTypes/clone";
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes/clone"
      );
      let payload = {
        iwtIds: iwtIds,
      };

      return this.axios({
        method: "post",
        headers: {
          AUTHORIZATION: "Bearer " + this.$store.state.jwt,
        },
        url: url,
        data: payload
      })
        .then((response) => {
          view.$helpers.success("Lohnarten kopiert", "Lohnarten wurden kopiert");
          view.refresh(true);
        })
        .catch(function (error) {
          console.log(error);
          view.$helpers.error("Fehler", "Lohnarten konnten nicht kopiert werden");
        });
    },

    toggleColorPicker(wageType, val = null) {
      let view = this;
      this.$helpers.OpenColorSelection(
        (color) => {
          wageType.color = color;
          view.saveColor(wageType);
        },
        {
          colorSelected: wageType.color,
          colorBlacklist: this.individualWageTypes.map((s) => {
            return { color: s.color, label: s.name };
          }),
        }
      );
    },
    saveColor(myObj) {
      let view = this;
      let url = "/api/sec/individualWageTypes/" + myObj.id;
      url = this.$helpers.appendParam(
        "companyId",
        this.$store.state.companyId,
        url,
        "/api/sec/individualWageTypes/" + myObj.id
      );
      let payload = {
        companyId: this.$store.state.companyId,
        color: myObj.color,
      };
      this.axios({
        method: "put",
        headers: {
          AUTHORIZATION: "Bearer " + view.$store.state.jwt,
        },
        url: url,
        data: payload,
      })
        .then((response) => {
          view.$helpers.success("Farbe geändert", "Farbe geändert");
          view.refresh(true);
        })
        .catch(function (error) {
          view.$helpers.error("Fehler", "Farbe konnte nicht geändert werden");
          console.log(error);
        });
    },
    setupUIDataMaps() {
      // console.log(this.uiDataMap);
      this.uiDataMap.employeeCategories.forEach(item => this.employeeCategoryMap[item.id] = item.label_de);
      this.uiDataMap.employeeLevels.forEach(item => this.employeeLevelMap[item.id] = item.label_de);
      this.uiDataMap.contractTypes.forEach(item => this.contractTypeMap[item.id] = item.label_de);
      this.uiDataMap.employmentTypes.forEach(item => this.employmentTypeMap[item.id] = item.label_de);
      this.uiDataMap.paymentTypes.forEach(item => this.paymentTypeMap[item.id] = item.label_de);
      this.uiDataMap.wageTypes.forEach(item => this.wageTypeMap[item.id] = item.label_de);
      this.uiDataMap.nightWorkerTypes.forEach(item => this.nightWorkerTypeMap[item.id] = item.label_de);
      this.uiDataMap.nightWorkModes.forEach(item => this.nightWorkModeMap[item.id] = item.label_de);
      this.uiDataMap.exitReasons.forEach(item => this.exitReasonMap[item.id] = item.label_de);
    },

    showModal(ref) {
      this.$refs[ref].show()
    },
    hideModal(ref) {
      this.$refs[ref].hide()
    },
  },
};
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>
.name-filter .fa-search {
  color: var(--contrast-4);
  position: absolute;
  top: 5px;
  left: 5px;
}

.half-width {
  width: 50%;
}

.fix-button-width {
  width: 150px;
}

.filter-input {
  margin-left: -45px;
  padding-left: 40px;
  width: calc(100% - 25px);
}

.list {
  width: calc(100% - 78px);
  margin: auto;
  height: 265px;
  box-shadow: inset 0 0 0 1px var(--contrast-3);
  overflow: hidden;
  position: absolute;
  border-radius: 5px;
}
.list .scroll-content {
  padding: 5px;
  overflow-y: auto;
  height: 265px;
}

.list-entry {
  height: 35px;
  margin-bottom: 2px;
  border-radius: 100px;
  cursor: pointer;
}

.list-entry:hover {
  background: var(--sub-menu);
  cursor: pointer;
}
.list-entry.selected {
  background: #e51550;
  cursor: pointer;
}
.list-entry.selected > .label,
.list-entry:hover > .label {
  color: var(--bg);
  cursor: pointer;
}
.list-entry.selected > .label > .label-small,
.list-entry.hover > .label > .label-small {
  color: var(--bg) !important;
  cursor: pointer;
}

.list-entry > .label {
  float: left;
  font-size: 14px;
  font-family: DistrictProBook;
  color: var(--contrast-4);
  padding-top: 6px;
  cursor: pointer;
}
.list-entry > .label > .label-small {
  cursor: pointer;
  color: var(--contrast-5);
}

.picture {
  float: left;
  border-radius: 100px;
  background: var(--contrast-1);
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.position-right {
  float: right;
  margin: 0;
  margin-right: -5px;
}
.pictureImg {
  border-radius: 100px;
  height: 25px;
  width: 25px;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--contrast-1);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--contrast-3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaaaaa;
}
.has-header > .has-header > .scrollbar-container {
  height: calc(100% - var(--header-height) - var(--tab-menu-height)) !important;
  overflow-x: hidden;
  top: var(--header-height);
  padding: 0px 14px;
}
</style>
